import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import { PageLayout } from '../components/PageLayout'
import { Hero } from '../components/Hero'
import { PortfolioProject } from '../components/PortfolioProject'
import { PageBlock } from '../components/PageBlock'

export const Head = () => (
    <>
        <title>Experience | Orientier Proposal Hub for MassBay Community College Website Refresh</title>
        <meta name="robots" content="noindex" />
    </>
)

const hero = (
    <Hero
        heading="Our Experience"
        subheading="Some key projects we've worked on, and why we feel they're relevant to MassBay"
    />
)

const projects = [
    {
        name: 'Her Campus Media',
        urls: [
            'https://www.hercampus.com',
            'https://www.hercampusmedia.com',
            'https://www.influencehercollective.com',
            'https://www.collegefashionista.com',
            'https://www.spoonuniversity.com',
        ],
        imageDir: 'hercampus',
        intro: [
            'Started by three Harvard undergrads in 2009, Her Campus Media has grown exponentially and now aptly bills itself as the #1 media portfolio for college students and Gen Z.',
            'We have worked closely with Her Campus for years, often taking a lead role on their flagship website and other projects.',
        ],
        highlights: [
            'We developed tech solutions for a distributed network with hundreds of global university chapters and thousands of content authors',
            'From the start, we enjoyed deep collaboration with client teams and the extended Her Campus community',
            'Her Campus has regularly trusted Orientier to advise on solutions architecture and technical strategy',
            'We worked directly with Her Campus design teams to execute multiple site redesigns and rebrandings',
            'We have implemented and refined multiple analytics tools and strategies to help Her Campus better understand their audience and content performance',
            'We unlocked technical challenges with advertising integrations and enabled massive growth in ad revenue',
            'We have researched, recommended, and implemented multiple platform evolutions and cost-saving initiatives',
            'We have helped Her Campus Media launch multiple new brands, campaigns, and websites',
        ],
        tech: [
            'Drupal',
            'Wordpress',
            'Cloudflare (CDN, security, performance)',
            'Brandfolder (DAM)',
            'Google Cloud Platform',
            'Acquia',
            'Pantheon',
            'AWS',
            'Squarespace',
            'React',
            'Javascript',
            'MySQL',
            'Google Ad Manager',
            '...and many more',
        ],
    },
    {
        name: 'Savannah College of Art and Design (SCAD)',
        urls: ['https://www.scad.edu'],
        imageDir: 'scad',
        intro: [
            'Nathanael Dewhurst, Orientier founder and our proposed tech lead for your MassBay.edu project, played a key role in the development of SCAD’s flagship website.',
            'He served as a team lead and solutions architect for the project, which was built on Drupal 7 a decade ago and - while undergoing various revisions since - withstood tests of time.',
        ],
        highlights: [
            'Numerous and invaluable on-site consultations with SCAD stakeholders, working closely with the SCAD marketing department and design team.',
            'Led workshops to (a) understand existing web content, structure, nav, UX, history, etc., (b) empathize with site users and stakeholders, and (c) iteratively develop architectural solutions.',
            'Balanced competing priorities and objectives, triaging extensive requirements and feature requests',
            'Served as a diplomatic bridge between teams/stakeholders',
            'Delivered a beautiful, responsive website (when that was a less ubiquitous concept) backed by a dynamic CMS',
            "Created structures and interfaces to communicate SCAD's academic programs, worldwide locations, events, and more",
            'Represented academic catalog data natively within the CMS',
            'Targeted key personas/user groups (prospective students, parents, alumni, faculty, etc.) with relevant content, navs, and calls to action',
            "Exhibited the school's extensive art and design portfolio throughout the site",
        ],
        tech: ['Drupal', 'Acquia', 'Responsive design', 'Full-stack development'],
    },
]

const ProjectPage = ({ data }) => {
    const images = {}
    data?.allFile?.group?.forEach((group) => {
        images[group?.fieldValue] = group?.edges
    })

    return (
        <PageLayout hero={hero} pageSlug="our-experience">
            <PageBlock variant="minimal">
                <Flex flexDir="column" alignItems="space-between" gap={8}>
                    {!!projects?.length &&
                        projects.map((project, index) => {
                            const projectImages =
                                typeof images[`projects/${project?.imageDir}`] !== 'undefined'
                                    ? images[`projects/${project?.imageDir}`]
                                    : []
                            const validImages = projectImages.filter((image) => image?.imgPrimary?.childImageSharp)
                            const { imageDir, ...extraProps } = project

                            return (
                                <>
                                    <PortfolioProject key={index} images={validImages} {...extraProps} />
                                    {index < projects.length - 1 && (
                                        <Text
                                            textAlign="center"
                                            color="massBayLightBlue.500"
                                            fontSize="9xl"
                                            lineHeight="none"
                                            fontFamily="serif"
                                        >
                                            ~
                                        </Text>
                                    )}
                                </>
                            )
                        })}
                </Flex>
            </PageBlock>
        </PageLayout>
    )
}

export default ProjectPage

export const pageQuery = graphql`
    query {
        allFile(
            filter: { relativeDirectory: { regex: "/projects/.+/" } }
            sort: { fields: [relativeDirectory, name], order: ASC }
        ) {
            group(field: relativeDirectory) {
                field
                fieldValue
                totalCount
                edges {
                    imgThumb: node {
                        childImageSharp {
                            gatsbyImageData(width: 100, height: 100, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                        }
                    }
                    imgPrimary: node {
                        childImageSharp {
                            gatsbyImageData(width: 480, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                        }
                    }
                    imgMedium: node {
                        childImageSharp {
                            gatsbyImageData(width: 400, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                        }
                    }
                }
            }
        }
    }
`
