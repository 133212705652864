import React from 'react'
import {
    Box,
    Heading,
    Flex,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    Link,
    Text,
    ListItem,
    List,
    useTheme,
} from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { EntitledList } from './EntitledList'

export const PortfolioProject = ({ name, urls, images, intro, highlights, notes, tech, children, ...extraProps }) => {
    const {
        constants: { LIST_VARIANT_BULLETPROOF },
    } = useTheme()
    let primaryImage = null
    if (images?.length) {
        const primaryImageItem = images[0]
        primaryImage = getImage(primaryImageItem?.imgPrimary)
    }

    return (
        <Flex {...extraProps} flexDir="column" gap={12} px={{ base: 0, md: 8 }}>
            <Flex
                flexDir={{ base: 'column', md: 'row' }}
                justifyContent="space-between"
                gap={8}
                alignItems={{ base: 'flex-start', md: 'center' }}
            >
                <Box
                    borderStyle="solid"
                    borderColor="massBayLightBlue.500"
                    borderWidth={{ base: 0, md: '1px 0 0 1px' }}
                    p={{ base: 0, md: 6 }}
                >
                    {name && (
                        <Heading as="h2" size="2xl">
                            {name}
                        </Heading>
                    )}
                    {!!urls?.length && (
                        <List as="ul" variant={LIST_VARIANT_BULLETPROOF}>
                            {urls.map((url, index) => (
                                <ListItem key={index} fontSize={{ base: 'md', md: 'lg' }} my={2}>
                                    <Link href={url} isExternal target="_blank" rel="noopener noreferrer">
                                        {url}
                                    </Link>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
                {primaryImage && (
                    <Box>
                        <GatsbyImage image={primaryImage} alt="" />
                    </Box>
                )}
            </Flex>
            <Flex flexDir="column" gap={12}>
                {!!intro?.length && (
                    <Box
                        fontStyle="italic"
                        px={{ base: 0, md: 6 }}
                        py={2}
                        borderStyle="solid"
                        borderColor="massBaySpringGreen.500"
                        borderWidth={{ base: 0, md: '0 0 0 1px' }}
                    >
                        {intro.map((note, index) => (
                            <Text key={index}>{note}</Text>
                        ))}
                    </Box>
                )}
                {!!highlights?.length && <EntitledList heading="Highlights" items={highlights} />}
                {!!tech?.length && (
                    <Box>
                        <EntitledList heading="Tech Elements" items={tech} />
                    </Box>
                )}
                {images?.length > 1 && (
                    <Flex gap={4} flexWrap="wrap" className={`images images${images?.length}`}>
                        {images.map(
                            (image, index) =>
                                index > 0 &&
                                image?.imgThumb &&
                                image?.imgMedium && (
                                    <Box
                                        key={index}
                                        _hover={{ cursor: 'pointer', transform: 'scale(1.1)' }}
                                        transition="0.3s all"
                                    >
                                        <Popover placement="top" size="xl">
                                            <PopoverTrigger>
                                                <Box>
                                                    <GatsbyImage image={getImage(image.imgThumb)} alt="" />
                                                </Box>
                                            </PopoverTrigger>
                                            <PopoverContent>
                                                <PopoverArrow />
                                                <PopoverBody>
                                                    <GatsbyImage image={getImage(image.imgMedium)} alt="" />
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                    </Box>
                                )
                        )}
                    </Flex>
                )}
                {!!children?.length && <Box>{children}</Box>}
            </Flex>
        </Flex>
    )
}
